import React from "react";
import { Link } from "gatsby-plugin-react-i18next";
import {
  getAnimationDelay,
  getAnimationDuration,
} from ".././helpers/animationHelper";

function Button(props) {
  let buttonDefaultClass = "v-button";
  let arrowMargin = "";

  if (props.type && props.type === "noBorder") {
    buttonDefaultClass = "v-info-button";
    arrowMargin = "no-margin";
  }

  return (
    <Link
      className={`v-button_container ${props.className || ""}`}
      to={props.to || ""}
      target={`${props.link == "external" ? "_blank" : ""}`}
      data-sal={props.animationDirection}
      data-sal-delay={props.animationDelay}
      data-sal-duration={getAnimationDuration()}
      data-sal-easing="ease"
    >
      <button className={`${buttonDefaultClass}`}>{props.title}</button>
      <svg
        className={`v-button_arrow ${arrowMargin}`}
        xmlns="http://www.w3.org/2000/svg"
        width="33.871"
        height="10.897"
        viewBox="0 0 33.871 10.897"
      >
        <g
          id="Group_2483"
          data-name="Group 2483"
          transform="translate(-816.412 -4411.361)"
        >
          <line
            id="Line_197"
            data-name="Line 197"
            x2="33.176"
            transform="translate(816.412 4416.715)"
            fill="none"
            stroke="#bd9b60"
            stroke-miterlimit="10"
            stroke-width="1"
          />
          <path
            id="Path_845"
            data-name="Path 845"
            d="M250.169,187.3l5.1,5.1-5.1,5.1"
            transform="translate(594.312 4224.411)"
            fill="none"
            stroke="#bd9b60"
            stroke-miterlimit="10"
            stroke-width="1"
          />
        </g>
      </svg>
    </Link>
  );
}

export default Button;
