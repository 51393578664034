export function getFocalPointCoordinates(focalPointObject) {
    let imageDimensions = focalPointObject.image.file.details.image;
    let focalPoints = focalPointObject.focalPoint.focalPoint;

    return {
        x: (focalPoints.x / imageDimensions.width) * 100,
        y: (focalPoints.y / imageDimensions.height) * 100
    }
};

export function getFocalPointsObjectPosition(focalPointObject) {
    let focalPoints = getFocalPointCoordinates(focalPointObject);
    return `${focalPoints.x}% ${focalPoints.y}%`;
}

export default {
    getFocalPointCoordinates: getFocalPointCoordinates,
    getFocalPointsObjectPosition: getFocalPointsObjectPosition
}
