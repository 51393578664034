export function getAnimationDelay(animationType) {
  if (animationType) {
    if (animationType.toLowerCase() === "slow") {
      return 600;
    } else if (animationType.toLowerCase() === "medium") {
      return 400;
    }
  }
  return 300;
}

export function getAnimationDuration() {
  return 1000;
}

export default {
  getAnimationDelay: getAnimationDelay,
  getAnimationDuration: getAnimationDuration,
};
