import React from 'react'
import { graphql, useStaticQuery } from "gatsby";
import { Link } from 'gatsby-plugin-react-i18next';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { getResource } from '../helpers/resourceHelper';
import vanelliLogo from '../images/Logo.svg';
import { FaInstagram } from 'react-icons/fa';
import { GrFacebookOption } from 'react-icons/gr';
import { FaLinkedinIn } from 'react-icons/fa'
import { IoLogoYoutube } from 'react-icons/io';
import '../scss/components/footer.scss';
import languageHelper from '../helpers/languageHelper';

const query = graphql`
query Footer {
  navs: allContentfulNavigation(filter: {slug: {eq: "footer"}}) {
    nodes {
      slug
      title
      node_locale
      subNavigations {
        ... on ContentfulNavigation {
          slug
          title
          node_locale
          subNavigations {
            ... on ContentfulNavigation {
              slug
              title
              node_locale
              subNavigations {
                ... on ContentfulNavigation {
                  slug
                  title
                  node_locale
                  subNavigations {
                    ... on ContentfulNavigation {
                      slug
                      node_locale
                      title
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  resources: allContentfulResource(filter: {key: {in: [
  "footer.copyright.label", 
  "footer.policy.label" , 
  "footer.policy.link" , 
  "footer.terms.label"
  "footer.terms.link",
  "footer.instagram.link",
  "footer.facebook.link",
  "footer.youtube.link",
  "footer.linkedin.link"
  ]}}) {
    edges {
      node {
        value {
          raw
        }
        node_locale
        key
      }
    }
  }
}
`;

function Footer(language) {
  const { navs, resources } = useStaticQuery(query);
  const navigation = languageHelper.getCurrentLanguageNodes(navs, language.language)[0];

  return (
    <footer>
      <div className="container main-container">
        <div className="footer-logo__container">
          <img className="footer-logo" alt="vanelli-logo" fluid="" src={vanelliLogo} />
        </div>
        <div className="row">
          {
            navigation && navigation.subNavigations && navigation.subNavigations.map((link, index) => {
              return (
                <div key={index} className="col nav-col">
                  <Link key={index} to={link.slug} className="nav-header">{link.title}</Link>
                  {
                    link.subNavigations &&
                    <nav className="nav flex-column">
                      {
                        link.subNavigations.map((subLink, subIndex) => {
                          return <Link key={subIndex} to={subLink.slug} className="nav-link">{subLink.title}</Link>
                        })
                      }
                    </nav>
                  }
                </div>
              )
            })
          }

        </div>
      </div>
      <div className="copyright-container">
        <div className="container">
          <div className="item-style">
            <p className="copyright">
              {documentToReactComponents(getResource(resources.edges, 'footer.copyright.label', language))}
            </p>
            <Link className="copyright-links" key="policy" to={getResource(resources.edges, 'footer.policy.link', language, 'string')}>
              <p className="copyright link-item">
                {documentToReactComponents(getResource(resources.edges, 'footer.policy.label', language))}
              </p>
            </Link>
            <Link key="terms" to={getResource(resources.edges, 'footer.terms.link', language, 'string')}>
              <p className="copyright link-item">
                {documentToReactComponents(getResource(resources.edges, 'footer.terms.label', language))}
              </p>
            </Link>
          </div>
          <div className="social-media-container">
            <nav className="flex-column">
              <a href={getResource(resources.edges, 'footer.instagram.link', language, 'string')} target="_blank" rel="noopener noreferrer" aria-label="instagram"><FaInstagram /></a>
              <a href={getResource(resources.edges, 'footer.facebook.link', language, 'string')} target="_blank" rel="noopener noreferrer" aria-label="facebook"><GrFacebookOption /></a>
              <a href={getResource(resources.edges, 'footer.linkedin.link', language, 'string')} target="_blank" rel="noopener noreferrer" aria-label="linkedin"><FaLinkedinIn /></a>
              <a href={getResource(resources.edges, 'footer.youtube.link', language, 'string')} target="_blank" rel="noopener noreferrer" aria-label="youtube"><IoLogoYoutube /></a>
            </nav>
          </div>
        </div>
      </div>

    </footer>
  )
}

export default Footer;
