import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import focalPointHelper from "../helpers/focalPointHelper";

const ImageWithFocalPoint = (props) => {
    if (props.image === null) return null;
    let focalPoints = focalPointHelper.getFocalPointsObjectPosition(props.image);

    return <GatsbyImage
        className={props.className}
        image={getImage(props.image.image)}
        alt={props.image.altText}
        objectPosition={focalPoints}
        style={props.style} />
}

export default ImageWithFocalPoint