import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import CookieConsent, { Cookies } from "react-cookie-consent";
import { getResource } from "../helpers/resourceHelper";
import "../scss/components/cookiebar.scss";

const query = graphql`
  query Cookiebar {
    resources: allContentfulResource(
      filter: { key: { in: ["cookiebar.label", "cookiebar.button.label"] } }
    ) {
      edges {
        node {
          value {
            raw
          }
          node_locale
          key
        }
      }
    }
  }
`;

const CookieBar = (language) => {
  const { resources } = useStaticQuery(query);
  function setCookie() {
    let cookieOptions = { expires: 0.001, sameSite: true, secure: true };
    Cookies.set("cookiesAllowed", false, cookieOptions);
  }
  return (
    <CookieConsent
      location="bottom"
      buttonText={documentToReactComponents(
        getResource(resources.edges, "cookiebar.button.label", language)
      )}
      disableStyles={true}
      cookieName="cookiesAllowed"
      containerClasses="cookie-bar"
      contentClasses="cookie-bar__content"
      buttonWrapperClasses="cookie-bar__button-container"
      buttonClasses="cookie-bar__button"
      expires={150}
      overlayClasses="cookie-bar__overlay"
      overlay
      onDecline={() => setCookie()}
      declineButtonText="X"
      enableDeclineButton
      declineButtonClasses="cookie-bar__close-button"
    >
      <div className="cookie-bar__container">
        {documentToReactComponents(
          getResource(resources.edges, "cookiebar.label", language)
        )}
      </div>
    </CookieConsent>
  );
};

export default CookieBar;
