import React from "react";
import { renderRichText } from '../../helpers/richTextHelper';
import '../../scss/components/hero/megaMenu.scss';
import { Link } from 'gatsby-plugin-react-i18next';
import ImageWithFocalPoint from "../imageWithFocalPoint";

const MegaMenu = ({ data }) => {
    return <div className="header__mega-menu__hero">
        <ImageWithFocalPoint
            image={data.mainImage}
            alt={data.mainImage.altText}
            style={{ position: "absolute" }} />
        <div className="header__mega-menu__description">
            {renderRichText(data.description.raw)}
        </div>
        <div className="header__mega-menu__cta">
            <Link to={data.ctaUrl}>{data.ctaLabel}</Link>
        </div>
    </div>
}


export default MegaMenu