import React from "react";
import Header from "./header";
import Footer from "./footer";
import Brands from "./../constants/brands";
import "../scss/main.scss";

function Layout({ children, language, brandTheme, className, noPageBanner }) {
  function getBrandClass(brandTheme) {
    switch (brandTheme) {
      case Brands.Contract.label:
        return "vanelli-contract";
      case Brands.Home.label:
        return "vanelli-home";
      case Brands.Textile.label:
        return "vanelli-textile";
      default:
        return "";
    }
  }
  return (
    <div className={getBrandClass(brandTheme) + ` ${className || ""}`}>
      <Header theme={brandTheme} noPageBanner={noPageBanner} />
      {children}
      {brandTheme != Brands.Catalogue.label && <Footer language={language} />}
    </div>
  );
}

export default Layout;
