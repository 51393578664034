import React from "react";
import { renderRichText } from "../../helpers/richTextHelper";
import "../../scss/components/slider/slider.scss";
import ReferenceSlideItem from "./referenceSlideItem";
import RelatedItemBox from "./relatedItemBox";
import { getResponsiveSettings } from "../../helpers/sliderHelper";
import ReactSlider from "react-slick";
import { Link } from "gatsby-plugin-react-i18next";
import ImageSlideItem from "./imageSlideItem";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import ImageWithText from "../imageWithText";
import {
  getAnimationDelay,
  getAnimationDuration,
} from "../../helpers/animationHelper";


const Slider = ({ data, optionalClass }) => {
  const slider = React.useRef(null);
  var settings = {
    infinite: data.type === "Images" ? true : false,
    speed: 500,
    slidesToShow: data.type === "ItemBoxes" ? 3 : data.numberOfSlides,
    slidesToScroll: 1,
    initialSlide: 0,
    variableWidth: data.type === "References" ? true : false,
    arrows: data.type === "Images" ? false : true,
    prevArrow:
      data.type === "Images" ? (
        ""
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16.019"
          height="29.209"
          viewBox="0 0 16.019 29.209"
        >
          <path
            id="Path_891"
            data-name="Path 891"
            d="M250.169,187.3l13.9,13.9-13.9,13.9"
            transform="translate(265.481 215.806) rotate(180)"
            fill="none"
            stroke="#bd9b60"
            stroke-width="2"
          />
        </svg>
      ),
    nextArrow:
      data.type === "Images" ? (
        ""
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16.019"
          height="29.209"
          viewBox="0 0 16.019 29.209"
        >
          <path
            id="Path_893"
            data-name="Path 893"
            d="M250.169,187.3l13.9,13.9-13.9,13.9"
            transform="translate(-249.462 -186.597)"
            fill="none"
            stroke="#bd9b60"
            stroke-width="2"
          />
        </svg>
      ),
    responsive: getResponsiveSettings(
      data.type,
      optionalClass === "imageWithText" ? true : false,
      data.slides ? data.slides.length : 0
    ),
  };

  function renderSlideItem(type, slide, index, borderStatus) {
    switch (type) {
      case "References":
        return (
          <ReferenceSlideItem
            slide={slide}
            index={index + 1}
            borderStatus={borderStatus}
          />
        );
      case "ItemBoxes":
        return <RelatedItemBox item={slide} />;
      case "Images":
        return <ImageSlideItem image={slide} index={index} />;
      default:
        return null;
    }
  }

  function next() {
    slider.current.slickNext();
  }
  function previous() {
    slider.current.slickPrev();
  }

  function getAlignment(alignmentType) {
    if (alignmentType === "Left") {
      return "left-align";
    } else if (alignmentType === "Right") {
      return "right-align";
    }
    return "center-align";
  }

  return (
    <div
      className={`slider-component__container ${
        data.type === "Images" ? "" : " small-arrow"
      } 
      ${optionalClass ? optionalClass : ""} ${
        data.isFullWidth ? "" : " half-width"
      }
      ${data.type === "References" ? " certificates" : ""}`}
    >
      <div className={`slider-container ${getAlignment(data.alignment)}`}>
        {(data.headLine || data.description) && (
          <div className="slider-header-container"
                data-sal="slide-up"
                data-sal-delay={getAnimationDelay()}
                data-sal-duration={getAnimationDuration()}
                data-sal-easing="ease">
            <div className="slider-info-container">
              {data.headLine && (
                <h2
                  className={`${
                    data.type === "Images" ? "image-slider-header" : ""
                  }`}
                >
                  {data.headLine}
                </h2>
              )}
              {data.description && (
                <div className="slider-description">
                  {renderRichText(data.description.raw)}
                </div>
              )}
            </div>
            {data.ctaLabel && (
              <div className="slider-cta-container">
                <Link role="button" className="v-button" to={data.ctaUrl}>
                  {data.ctaLabel}
                </Link>
              </div>
            )}
          </div>
        )}
        
        <div
                className={`slider-slide-item-container ${data.type}`}
                data-sal="slide-up"
                data-sal-delay={getAnimationDelay("medium")}
                data-sal-duration={getAnimationDuration()}
                data-sal-easing="ease"
                > 

          <ReactSlider ref={slider} {...settings}>
            {data.slides.map((slide, i) => {
              const borderStatus =
                optionalClass === "imageWithText" ? "noBorder" : "";
              return renderSlideItem(data.type, slide, i, borderStatus);
            })}
          </ReactSlider>
          {data.type === "Images" && data.slides.length > 1 && (
            <div
              className={`image-slide_arrow-container ${getAlignment(
                data.alignment
              )}`}
            >
              <div className="image-slide_arrow-circle">
                <BsArrowLeft onClick={next} className="image-slide_arrow" />
              </div>
              <div className="image-slide_arrow-circle">
                <BsArrowRight
                  onClick={previous}
                  className="image-slide_arrow"
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {data.sliderInfoPanel && (
        <div className="slider-component__info-panel">
          <ImageWithText data={data.sliderInfoPanel} />
        </div>
      )}
    </div>
  );
};

export default Slider;
