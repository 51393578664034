import React from "react";
import { renderRichText } from "../../helpers/richTextHelper";
import Button from './../button';
import {
  getAnimationDelay,
  getAnimationDuration,
} from "../../helpers/animationHelper";
import "../../scss/components/hero/informational.scss";
import ImageWithFocalPoint from "./../imageWithFocalPoint";
import CertificatesSlider from "../Slider/slider";

const Informational = ({ data }) => {
  let customSlider = {
    type: "References",
    isFullWidth: true,
    slides: data.certificates ? data.certificates.certicateList : null,
  };
  return (
    <div className="hero-container">
      <h2
        data-sal="slide-right"
        className={data.mainImage == null ? "with-no-image" : ""}
        data-sal-delay={getAnimationDelay("medium")}
        data-sal-duration={getAnimationDuration()}
        data-sal-easing="ease"
      >
        {data.title}
      </h2>
      <div
        className={`right-column-container ${
          data.mainImage == null ? "with-no-image" : ""
        }`}
      >
        <div
            className="right-column"
            data-sal="slide-left"
            data-sal-delay={getAnimationDelay("slow")}
            data-sal-duration={getAnimationDuration()}
            data-sal-easing="ease"
          >
          {data.mainImage && (
            <ImageWithFocalPoint
              className="main-image"
              image={data.mainImage}
            />
          )}
          <div
            className="hero-text"
            data-sal="slide-left"
            data-sal-delay={getAnimationDelay("medium")}
            data-sal-duration={getAnimationDuration()}
            data-sal-easing="ease"
          >
            {renderRichText(data.description.raw)}
            {data.certificates && (
              <CertificatesSlider
                data={customSlider}
                optionalClass="informational"
              />
            )}
          </div>
        </div>
      </div>
      {data.ctaLabel && (
        <div
          className="button-container"
          data-sal="slide-right"
          data-sal-delay={getAnimationDelay("slow")}
          data-sal-duration={getAnimationDuration()}
          data-sal-easing="ease"
        >
          <Button title={data.ctaLabel} to={data.ctaUrl} type={`noBorder`} />
        </div>
      )}
    </div>
  );
};

export default Informational;
