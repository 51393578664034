import React from "react";
import "../scss/components/imageWithText.scss";
import ImageWithFocalPoint from "./imageWithFocalPoint";
import { renderRichText } from "../helpers/richTextHelper";
import {
  getAnimationDelay,
  getAnimationDuration,
} from "../helpers/animationHelper";
import Button from "./button";
import CertificatesSlider from "./Slider/slider";
import DownloadLink from "./Generic/downloadLink";

const ImageWithText = ({ data }) => {
  const layoutInfo = data.textPosition
    ? data.textPosition.split(" ")
    : ["Top", "Left"];
  const isTop = layoutInfo[0] === "Top";
  const isLeft = layoutInfo[1] === "Left";
  let customSlider = {
    type: "References",
    isFullWidth: true,
    slides: data.certificates ? data.certificates.certicateList : null,
  };
  return (
    <div
      className={`image-with-text-container ${
        isLeft ? "left-aligned" : "right-aligned"
      } ${isTop ? "at-top" : "at-bottom"}`}
    >
      <div className="text-column">
        {data.hideTitle === false && <div class="text-title">{data.title}</div>}
        <div
          className="text-container"
          data-sal="slide-up"
          data-sal-delay={getAnimationDelay()}
          data-sal-duration={getAnimationDuration()}
          data-sal-easing="ease"
        >
          {data.description && renderRichText(data.description.raw)}
        </div>
        <div
          data-sal="slide-up"
          data-sal-delay={getAnimationDelay("slow")}
          data-sal-duration={getAnimationDuration()}
          data-sal-easing="ease"
        >
          {data.document && <DownloadLink link={data.document.file.url} />}
        </div>
        {data.ctaLabel && data.ctaUrl && (
          <div className="cta-button-container">
            <div
              className="button-container"
              data-sal="slide-right"
              data-sal-delay={getAnimationDelay("slow")}
              data-sal-duration={getAnimationDuration()}
              data-sal-easing="ease"
            >
              <Button
                title={data.ctaLabel}
                type={`noBorder`}
                to={data.ctaUrl}
              />
            </div>
          </div>
        )}
        {data.certificates && (
          <CertificatesSlider
            data={customSlider}
            optionalClass="imageWithText"
          />
        )}
      </div>

      <div
              className="image-column"
              data-sal="slide-up"
              data-sal-delay={getAnimationDelay("medium")}
              data-sal-duration={getAnimationDuration()}
              data-sal-easing="ease"
          > 
        {data.image && (
          <ImageWithFocalPoint className="main-image" image={data.image} />
        )}
      </div>
    </div>
  )
};

export default ImageWithText;
