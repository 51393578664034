export function getResponsiveSettings(type, isSmallIcons, itemCount) {
  var responsiveSettingsCircularSlider = [
    {
      breakpoint: 2500,
      settings: {
        slidesToShow:
          (isSmallIcons ? 7 : 5) > itemCount ? itemCount : isSmallIcons ? 7 : 5,
      },
    },
    {
      breakpoint: 1700,
      settings: {
        slidesToShow:
          (isSmallIcons ? 5 : 4) > itemCount ? itemCount : isSmallIcons ? 5 : 4,
      },
    },
    {
      breakpoint: 1300,
      settings: {
        slidesToShow:
          (isSmallIcons ? 4 : 3) > itemCount ? itemCount : isSmallIcons ? 4 : 3,
      },
    },
    {
      breakpoint: 1100,
      settings: {
        slidesToShow:
          (isSmallIcons ? 3 : 3) > itemCount ? itemCount : isSmallIcons ? 3 : 3,
      },
    },
    {
      breakpoint: 960,
      settings: {
        slidesToShow:
          (isSmallIcons ? 6 : 4) > itemCount ? itemCount : isSmallIcons ? 6 : 4,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow:
          (isSmallIcons ? 5 : 4) > itemCount ? itemCount : isSmallIcons ? 5 : 4,
      },
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow:
          (isSmallIcons ? 4 : 3) > itemCount ? itemCount : isSmallIcons ? 4 : 3,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow:
          (isSmallIcons ? 3 : 2) > itemCount ? itemCount : isSmallIcons ? 3 : 2,
      },
    },
  ];
  var responsiveSettingsImageBoxesSlider = [
    {
      breakpoint: 3000,
      settings: {
        slidesToShow: 3 < itemCount ? 3 : itemCount,
      },
    },
    {
      breakpoint: 1800,
      settings: {
        slidesToShow: 2 < itemCount ? 2 : itemCount,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
        centerPadding: "0px",
        centerMode: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        className: "center",
        centerPadding: "10px",
        centerMode: true,
      },
    },
  ];
  var responsiveSettingsImageSlider = [
    {
      breakpoint: 5000,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: 640,
      settings: {
        slidesToShow: 1,
        className: "center",
        centerPadding: "0px",
        centerMode: true,
      },
    },
  ];
  return type === "ItemBoxes"
    ? responsiveSettingsImageBoxesSlider
    : type === "Images"
    ? responsiveSettingsImageSlider
    : responsiveSettingsCircularSlider;
}

export default {
  getResponsiveSettings: getResponsiveSettings,
};
