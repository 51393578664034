import React from "react";
import MegaMenu from "./Hero/megaMenu";
import Informational from "./Hero/informational";
import Discover from "./Hero/discover";
import OurStories from "./Hero/ourStories";
import CollectionSnippet from "./Hero/collectionSnippet";

const Hero = ({ data }) => {
  if (data) {
    switch (data.type) {
      case "MegaMenu":
        return <MegaMenu data={data} />;
      case "Informational":
        return <Informational data={data} />;
      case "Discover":
        return <Discover data={data} />;
      case "OurStories":
        return <OurStories data={data} />;
      case "Collections Snippet":
        return <CollectionSnippet data={data} />;
      default:
        return null;
    }
  } else {
    return null;
  }
};

export default Hero;
