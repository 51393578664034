import React from 'react';
import { Link, useTranslation } from 'gatsby-plugin-react-i18next';

const Languages = ({ languages, originalPath, language, className }) => {
    const { t } = useTranslation();

    return (
        <ul className={`header-languages ${className || ''}`}>
            {
                languages.map((lang, index) => (
                    <li key={index}>
                        <Link to={originalPath} language={lang} className={`dropdown-item ${lang === language ? 'is-active' : ''}`}>{t(`locales.${lang}`)}</Link>
                        {languages.length - 1 !== index && <div className="separator-pipe">&#124;</div>}
                    </li>
                ))
            }
        </ul>
    )
}

export default Languages;
