import React, { useState } from "react";
import Navigation from "./navigation";
import { BsSearch } from "react-icons/bs";
import { BiMenuAltLeft } from "react-icons/bi";
import { useI18next } from "gatsby-plugin-react-i18next";
import Button from "./button";
import { graphql, useStaticQuery } from "gatsby";
import { getResource } from "../helpers/resourceHelper";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import "../scss/components/header.scss";
import Languages from "./languages";
import Brands from "../constants/brands";
import ContractLogo from "../images/svgs/logo-vanelli-contract.svg";
import HomeLogo from "../images/svgs/logo-vanelli-home.svg";
import TextileLogo from "../images/svgs/logo-vanelli-textile.svg";
import Logo from "../images/svgs/logo-vanelli.svg";

const query = graphql`
  query HeaderResources {
    resources: allContentfulResource(
      filter: {
        key: {
          in: [
            "header.contactus.label"
            "backbutton.label"
            "header.search.placeholder"
            "header.search.back"
          ]
        }
      }
    ) {
      edges {
        node {
          value {
            raw
          }
          node_locale
          key
        }
      }
    }
  }
`;

const Header = ({ theme, noPageBanner }) => {
  const { languages, originalPath, language, navigate } = useI18next();
  const [isShown, setIsShown] = useState(false);
  const [isSearchShown, setIsSearchShown] = useState(false);
  const [searchText, setsearchText] = useState(null);
  const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);
  const { resources } = useStaticQuery(query);

  const isBrowser = () => typeof document !== "undefined";
  const hasPageBanner =
    isBrowser() && document.getElementsByClassName("no-page-banner").length > 0;

  const onKeyDown = (event) => {
    if (event.key === "Enter" || event.key === "NumpadEnter") {
      event.preventDefault();
      event.stopPropagation();

      if (searchText && searchText !== "")
        navigate("/search-results/?q=" + searchText);
    }
  };
  const renderLogo = () => {
    switch (theme) {
      case Brands.Contract.label:
        return (
          <ContractLogo
            className={`header-logo${
              isShown || isSearchShown ? " menu-opened" : ""
            }`}
          />
        );
      case Brands.Home.label:
        return (
          <HomeLogo
            className={`header-logo${
              isShown || isSearchShown ? " menu-opened" : ""
            }`}
          />
        );
      case Brands.Textile.label:
        return (
          <TextileLogo
            className={`header-logo${
              isShown || isSearchShown ? " menu-opened" : ""
            }`}
          />
        );
      default:
        return (
          <Logo
            className={`header-logo single-line${
              isShown || isSearchShown ? " menu-opened" : ""
            }`}
          />
        );
    }
  };

  const renderLink = () => {
    switch (theme) {
      case Brands.Contract.label:
        return "/vanelli-contract";
      case Brands.Home.label:
        return "/vanelli-home";
      case Brands.Textile.label:
        return "/vanelli-textile";
      default:
        return "/";
    }
  };

  return (
    <header className={`header ${isShown || isSearchShown ? "opened" : ""}`}>
      <div className="header-container">
        <div
          className={`header__menu-icon ${
            noPageBanner ? "no-page-banner" : ""
          }`}
          role="button"
          tabIndex={0}
          onClick={() => setIsMobileMenuOpened(true)}
          onKeyDown={() => setIsMobileMenuOpened(true)}
        >
          <BiMenuAltLeft />
        </div>
        <div className={`header-left ${isMobileMenuOpened ? "is-active" : ""}`}>
          <Navigation
            megaMenuOpened={setIsShown}
            isMobileMenuOpened={isMobileMenuOpened}
            setIsMobileMenuOpened={setIsMobileMenuOpened}
            backButtonLabel={documentToReactComponents(
              getResource(resources.edges, "backbutton.label", language)
            )}
          />
          <Languages
            languages={languages}
            originalPath={originalPath}
            language={language}
            className="header__mobile-languages"
          />
        </div>
        <div
          className="header-center"
          role="button"
          tabIndex={0}
          onClick={() => navigate(renderLink())}
          onKeyDown={() => navigate(renderLink())}
        >
          {renderLogo()}
        </div>
        <div
          className="header-right"
          onMouseLeave={() =>
            !isMobileMenuOpened ? setIsSearchShown(false) : ""
          }
        >
          <BsSearch
            className="search-icon"
            onMouseEnter={() =>
              !isMobileMenuOpened && !hasPageBanner
                ? setIsSearchShown(true)
                : ""
            }
          />
          <div className={`search-bar ${isSearchShown ? "opened" : ""}`}>
            <div
              className="back-button"
              onClick={() =>
                !isMobileMenuOpened ? setIsSearchShown(false) : ""
              }
            >
              {getResource(
                resources.edges,
                "header.search.back",
                language,
                "string"
              )}
            </div>
            <BsSearch color="black" fontSize="2.1em" className="search-logo" />
            <input
              type="text"
              className="search-box"
              onChange={(e) => setsearchText(e.target.value)}
              placeholder={getResource(
                resources.edges,
                "header.search.placeholder",
                language,
                "string"
              )}
              onKeyDown={(e) => onKeyDown(e)}
            />
          </div>
          <Languages
            languages={languages}
            originalPath={originalPath}
            language={language}
          />
          <Button
            to="/contact-us"
            className="header__contact-us-btn"
            title={documentToReactComponents(
              getResource(resources.edges, "header.contactus.label", language)
            )}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
