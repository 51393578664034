import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

function getOptions(content) {
    let options = {
      renderMark: {
        [MARKS.BOLD]: (text) => <span className="bold-text">{text}</span>,
        [MARKS.ITALIC]: (text) => <span className="italic-text">{text}</span>,
      },
      renderNode: {
        [INLINES.HYPERLINK]: (node, children) => {
          const { uri } = node.data;
          return (
            <a href={uri} className="underline-text">
              {children}
            </a>
          );
        },
        [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
          const item_id = node.data.target.sys.id;
          const itemList = content.references;
          const item = itemList.find((x) => x.contentful_id === item_id);
          if (!item.gatsbyImageData) return <></>;
          return (
            <GatsbyImage
              image={getImage(item)}
              alt={item.gatsbyImageData.description}
            />
          );
        },
      },
    };
    return options;
}

export function renderRichText(raw, options) {
    return documentToReactComponents(JSON.parse(raw), options ? options : getOptions());
};

export default {
    renderRichText: renderRichText
}
