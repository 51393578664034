import React from "react";
import { renderRichText } from "../../helpers/richTextHelper";
import "../../scss/components/hero/collectionSnippet.scss";
import { Link } from "gatsby-plugin-react-i18next";
import ImageWithFocalPoint from "../imageWithFocalPoint";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

const CollectionSnippet = ({ data }) => {
  const breakpoints = useBreakpoint();
  return (
    <div className="collection-snippet__container">
      {!breakpoints.sm && (
        <div className="collection-snippet__left-column">
          <ImageWithFocalPoint
            className="main-image"
            image={data.mainImage}
            alt={data.mainImage.altText}
          />
        </div>
      )}

      <div className="collection-snippet__right-column">
        {!breakpoints.sm && (
          <ImageWithFocalPoint
            className="collection-snippet__top-image "
            image={data.topImage}
            alt={data.topImage.altText}
          />
        )}
        <div className="collection-snippet__description-container">
          <div className="collection-snippet__text-container">
            <h2 className="collection-snippet__header">{data.title}</h2>
            {renderRichText(data.description.raw)}
          </div>
          <Link className="v-info-button" to={data.ctaUrl}>
            {data.ctaLabel}
          </Link>
        </div>
        {!breakpoints.sm && (
          <ImageWithFocalPoint
            className="collection-snippet__bottom-image"
            image={data.bottomImage}
            alt={data.bottomImage.altText}
          />
        )}
      </div>
      {breakpoints.sm && (
        <div className="collection-snippet__mobile-image-container">
          <div className="collection-snippet__two-images">
            <ImageWithFocalPoint
              className="top-image"
              image={data.topImage}
              alt={data.topImage.altText}
            />
            <ImageWithFocalPoint
              className="bottom-image"
              image={data.bottomImage}
              alt={data.bottomImage.altText}
            />
          </div>
          <ImageWithFocalPoint
            className="main-image"
            image={data.mainImage}
            alt={data.mainImage.altText}
          />
        </div>
      )}
    </div>
  );
};

export default CollectionSnippet;
