import React from "react";
import ImageWithFocalPoint from "../imageWithFocalPoint";
import Button from "../button";
import "../../scss/components/slider/relatedItemBox.scss";
import {
  getAnimationDelay,
  getAnimationDuration,
} from "../../helpers/animationHelper";

const RelatedItemBox = ({ item, type, index }) => {
  return (
    <div
      key={index}
      className="related-item-box__container"
      data-sal={index % 2 ? "slide-up" : "slide-right"}
      data-sal-delay={getAnimationDelay()}
      data-sal-duration={getAnimationDuration()}
      data-sal-easing="ease"
    >
      <ImageWithFocalPoint
        image={item.backgroundImage}
        alt={item.backgroundImage.altText}
        className="related-item-box__image"
      />
      <div className="related-item-box__detail-container">
        <p className="related-item-box__item-title">{item.title}</p>
        {item.ctaLabel && item.ctaUrl && (
          <Button title={item.ctaLabel} to={item.ctaUrl} type="noBorder" />
        )}
      </div>
    </div>
  );
};

export default RelatedItemBox;
