export default {
  Contract: {
    label: "Vanelli Contract",
  },
  Textile: {
    label: "Vanelli Textile",
  },
  Home: {
    label: "Vanelli Home",
  },
  Catalogue: {
    label: "Catalogue",
  },
};
