import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { RiArrowRightSLine } from "react-icons/ri";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import { GrClose } from "react-icons/gr";
import { IoIosArrowBack } from "react-icons/io";
import { IconContext } from "react-icons";
import { getResource } from "../helpers/resourceHelper";
import Button from "./button";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Hero from "./hero";
import { isDesktop } from "react-device-detect";
import languageHelper from "../helpers/languageHelper";
import { GoHome } from "react-icons/go";

const query = graphql`
  query Nav {
    navs: allContentfulNavigation(filter: { slug: { eq: "header" } }) {
      nodes {
        node_locale
        subNavigations {
          ... on ContentfulNavigation {
            subNavigations {
              ... on ContentfulNavigation {
                __typename
                title
                slug
                node_locale
                subNavigations {
                  ... on ContentfulNavigation {
                    title
                    slug
                    node_locale
                  }
                }
              }
              ... on ContentfulHero {
                ...HeroFragment
              }
            }
            title
            slug
          }
        }
      }
    }

    resources: allContentfulResource(
      filter: {
        key: {
          in: [
            "header.contactus.label"
            "mega.menu.catalog.button.cta.url"
            "mega.menu.catalog.button.cta.label"
          ]
        }
      }
    ) {
      edges {
        node {
          value {
            raw
          }
          node_locale
          key
        }
      }
    }
  }
`;

const Navigation = (props) => {
  const { language } = useI18next();
  const { navs, resources } = useStaticQuery(query);
  const navigation = languageHelper.getCurrentLanguageNodes(navs, language)[0];
  const [isBackVisible, setIsBackVisible] = useState(false);
  const navigationsArray = navigation.subNavigations.map(function (nav, index) {
    return { ind: index, show: false };
  });

  const [navigations, setNavigations] = useState(navigationsArray);

  function OpenMobileSubMenu(index, isOpen) {
    let items = [...navigations];

    items.forEach(function (item, ind) {
      item.show = ind === index ? isOpen : false;
    });

    setNavigations(items);
    setIsBackVisible(isOpen);
  }

  function toggleMegaMenu(index, isOpen) {
    let items = [...navigations];

    items.forEach(function (item, ind) {
      item.show = ind === index ? isOpen : false;
    });

    props.megaMenuOpened(isOpen);
    setNavigations(items);
  }

  function closeAllMenus() {
    let items = [...navigations];

    items.forEach(function (item) {
      item.show = false;
    });

    props.megaMenuOpened(false);
    setNavigations(items);
    props.setIsMobileMenuOpened(false);
  }

  const controlNavbar = () => {
    if (window.scrollY > 50) {
      closeAllMenus();
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
  }, []);

  return (
    <nav className={"navbar"}>
      <div>
        <div
          className={`header__mobile-menu__head ${
            isBackVisible ? "back-visible" : ""
          }`}
        >
          {!isBackVisible && (
          <div role="presentation" className="nav-item">
            <Link to="/" className="header__nav-link">
              <GoHome />
            </Link>
          </div>
          )}
          {isBackVisible && (
            <div
              className="header__mobile-menu__back-button"
              role="button"
              tabIndex={0}
              onClick={() => OpenMobileSubMenu(0, false)}
              onKeyDown={() => OpenMobileSubMenu(0, false)}
            >
              <IconContext.Provider value={{ className: "back-button__icon" }}>
                <IoIosArrowBack />
              </IconContext.Provider>
              <span>{props.backButtonLabel}</span>
            </div>
          )}
          <GrClose onClick={() => closeAllMenus()} />
        </div>
        <div className="header__mobile-menu__bottom">
          <Link
            to={getResource(
              resources.edges,
              "mega.menu.catalog.button.cta.url",
              language,
              "string"
            )}
          >
            <button className={`header__mega-dropdown-catalogue-button`}>
              {documentToReactComponents(
                getResource(
                  resources.edges,
                  "mega.menu.catalog.button.cta.label",
                  language
                )
              )}
            </button>
          </Link>
          <Button
            to="/contact-us"
            className="header__contact-us-btn"
            title={documentToReactComponents(
              getResource(resources.edges, "header.contactus.label", language)
            )}
          />
        </div>
      </div>
      <ul className="nav">
        {!props.isMobileMenuOpened && (
          <li role="presentation" className="nav-item home">
            <Link to="/" className="header__nav-link">
              <GoHome />
            </Link>
          </li>
        )}
        {navigation.subNavigations.map(function (subLink, subIndex) {
          return (
            <li
              key={subIndex}
              role="presentation"
              className={`nav-item ${
                navigations[subIndex].show ? "is-active" : ""
              }`}
              onMouseEnter={() =>
                !props.isMobileMenuOpened ? toggleMegaMenu(subIndex, true) : ""
              }
              onMouseLeave={() =>
                !props.isMobileMenuOpened ? toggleMegaMenu(subIndex, false) : ""
              }
            >
              <Link to={subLink.slug} className="header__nav-link">
                {subLink.title}
              </Link>
              {props.isMobileMenuOpened && (
                <RiArrowRightSLine
                  onClick={() => OpenMobileSubMenu(subIndex, true)}
                />
              )}
              <div
                className={`header__mega-dropdown ${
                  navigations[subIndex].show ? "mobile-active" : ""
                }`}
              >
                <div className="header__mega-dropdown-container">
                  <Link
                    to={subLink.slug}
                    className="header__mega-dropdown-link"
                  >
                    {subLink.title}
                  </Link>
                  {subLink.subNavigations &&
                    subLink.subNavigations.length &&
                    subLink.subNavigations.map(function (subNavigation, index) {
                      if (subNavigation.__typename === "ContentfulNavigation") {
                        return (
                          <div
                            key={index}
                            className="header__mega-dropdown-column"
                          >
                            <ul>
                              <li className="header__mega-dropdown-item header__mega-dropdown-title">
                                <Link
                                  to={subNavigation.slug}
                                  className={`${
                                    subNavigation.slug ? "" : "no-click"
                                  }`}
                                >
                                  {subNavigation.title}
                                </Link>
                              </li>
                              {subNavigation.subNavigations &&
                                subNavigation.subNavigations.length &&
                                subNavigation.subNavigations.map(function (
                                  lastNav,
                                  lastNavIndex
                                ) {
                                  return (
                                    <li
                                      key={lastNavIndex}
                                      className={`header__mega-dropdown-item`}
                                    >
                                      <Link
                                        to={lastNav.slug}
                                        className={`${
                                          lastNav.slug ? "" : "no-click"
                                        }`}
                                      >
                                        {lastNav.title}
                                      </Link>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        );
                      } else if (isDesktop) {
                        return (
                          <div
                            key={index}
                            className="header__mega-dropdown-column"
                          >
                            <Hero data={subNavigation} />
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  
                  <div className="header__mega-dropdown-column">
                    <ul>
                      <li className="header__mega-dropdown-item header__mega-dropdown-title">
                        <Link
                          to={getResource(
                            resources.edges,
                            "mega.menu.catalog.button.cta.url",
                            language,
                            "string"
                          )}
                        >
                          <button
                            className={`header__mega-dropdown-catalogue-button`}
                          >
                            {documentToReactComponents(
                              getResource(
                                resources.edges,
                                "mega.menu.catalog.button.cta.label",
                                language
                              )
                            )}
                          </button>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Navigation;
