import React from "react";
import { renderRichText } from '../../helpers/richTextHelper';
import { getAnimationDelay, getAnimationDuration } from '../../helpers/animationHelper';
import '../../scss/components/hero/ourStories.scss';
import ImageWithFocalPoint from "../imageWithFocalPoint";
import Button from "./../button";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const OurStories = ({ data }) => {
    const breakpoints = useBreakpoint();

    return <div className={`hero__ourStory-container ${data.isImageLeftAligned === false ? 'left-aligned' : ''}`}>
         <div
              className="left-column"
              data-sal="slide-left"
              data-sal-delay={getAnimationDelay()}
              data-sal-duration={getAnimationDuration()}
              data-sal-easing="ease"
            >   
                    <ImageWithFocalPoint className="main-image" image={data.mainImage} alt={data.mainImage.altText} />
        </div>
        <div className="right-column">
            <div className="hero-text" data-sal="slide-up" data-sal-delay={getAnimationDelay()} data-sal-duration={getAnimationDuration()} data-sal-easing="ease">
                <h2 data-sal="slide-up">{data.title}</h2>
                {renderRichText(data.description.raw)}
                <Button title={data.ctaLabel} to={data.ctaUrl} />
                {!breakpoints.md && <div className="image-row" data-sal="slide-up" data-sal-delay={getAnimationDelay("slow")} data-sal-duration={getAnimationDuration()} data-sal-easing="ease">
                    <ImageWithFocalPoint className="square-image" image={data.secondaryImage} />
                    <ImageWithFocalPoint className="square-image" image={data.topImage} />
                    <ImageWithFocalPoint className="square-image" image={data.bottomImage} />
                </div>}
            </div>
        </div>
    </div>
}


export default OurStories