import React from "react";
import ImageWithFocalPoint from "../imageWithFocalPoint";
import "../../scss/components/slider/imageSlideItem.scss";

const ImageSlideItem = ({ image, index }) => {
  return (
    <div key={index} className="slick-slide-item_image-container">
      <ImageWithFocalPoint
        className="slick-slide-item_image"
        image={image}
        alt={image.altText}
      />
    </div>
  );
};

export default ImageSlideItem;
