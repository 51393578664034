import React from "react";
import { renderRichText } from "../../helpers/richTextHelper";
import { getAnimationDelay, getAnimationDuration } from "../../helpers/animationHelper";
import "../../scss/components/hero/discover.scss";
import Button from "../button";
import ImageWithFocalPoint from "../imageWithFocalPoint";

const Discover = ({ data }) => {
  return (
    <div
      className="discover__container"
      data-sal="slide-up"
      data-sal-delay={getAnimationDelay()}
      data-sal-duration={getAnimationDuration()}
      data-sal-easing="ease"
    >
      <ImageWithFocalPoint
        className="discover__background-image"
        image={data.mainImage}
        alt={data.mainImage.altText}
      />
      <div className="discover__text-container">
        <h2 className="discover__title">{data.title}</h2>
        <p className="discover__description">
          {renderRichText(data.description.raw)}
        </p>
        <div className="discover__button-container">
          <Button title={data.ctaLabel} to={data.ctaUrl} />
        </div>
      </div>
    </div>
  );
};

export default Discover;
