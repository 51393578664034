import React from "react";
import "../../scss/components/slider/referenceSlideItem.scss";
import ImageWithFocalPoint from "../imageWithFocalPoint";
import ReactSvg from "../Generic/reactSvg";

const ReferenceSlideItem = ({ slide, index, borderStatus, subType }) => {
  return (
    <div className="slideItem-container">
      <div className={`slideItem-image-container ${borderStatus}`}>
        {slide.image && slide.image.gatsbyImageData ? (
          <ImageWithFocalPoint
            image={slide}
            alt={slide.altText}
            className="slideItem-image"
          />
        ) : (
          slide.image.file.url && (
            <ReactSvg url={slide.image.file.url} className="slideItem-image" />
          )
        )}
      </div>
    </div>
  );
};

export default ReferenceSlideItem;
