import React from "react";
import "../../scss/components/generic/downloadLink.scss";
import { FiDownload } from "react-icons/fi";
import { useI18next } from "gatsby-plugin-react-i18next";
import { graphql, useStaticQuery } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { getResource } from "../../helpers/resourceHelper";

const query = graphql`
  query DownloadLink {
    resources: allContentfulResource(
      filter: { key: { in: ["downloadLink.button.label"] } }
    ) {
      edges {
        node {
          value {
            raw
          }
          node_locale
          key
        }
      }
    }
  }
`;

function DownloadLink({ link }) {
  const { language } = useI18next();
  const { resources } = useStaticQuery(query);
  return (
    <a className="buttonLink" href={link} download="FileName" target="_blank" rel="noreferrer">
      <div className="download-link_container">
        <FiDownload className="download-link_icon" />
        <p className="download-link_text">
          {documentToReactComponents(
            getResource(resources.edges, "downloadLink.button.label", language)
          )}
        </p>
      </div>
    </a>
  );
}

export default DownloadLink;
