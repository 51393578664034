var _ = require('lodash');

export function getResource(list, key, language, returnType) {
    /*
    returnType=string for getting just text of resource (especially for links)
    othervise object is getting with inline css 
    */
    if (list.length) {
        let result = _.find(list, function (resource) {
            return resource.node.key === key && resource.node.node_locale === (typeof(language) === "string" ? language :  language.language)
        });

        if (result) {
            var parsedObject = JSON.parse(result.node.value.raw);
            if (returnType && returnType === "string") {
                if (parsedObject && parsedObject.content && parsedObject.content.length > 0) {
                    var mainContent = parsedObject.content[0];
                    if (mainContent && mainContent.content && mainContent.content.length > 0) {
                        return mainContent.content[0].value;
                    }
                }

            }
            return parsedObject;
        } else {
            return "";
        }
    }
};

export default {
    getResource: getResource
}
